<template>
  <div class="view-terms py-5 text-light">
    <div class="container pb-5">
      <h1 class="text-center mb-5 px-5">
        <router-link :to="{ name: 'index' }">
          <img class="img-fluid w-25" src="@/assets/images/logo.png" />
        </router-link>
      </h1>
      <h1 class="mb-5">Política de privacidade</h1>

      <div class="text-secondary">
        <h2>Quem somos</h2>

        <p>
          O site www.italomarsili.com.br é propriedade da empresa WRL
          Treinamentos, de propriedade do Dr. Italo Marsili e Arno Alcântara
          Júnior.
        </p>

        <h2>Quais dados pessoais coletamos e porque</h2>

        <h3>Comentários</h3>

        <p>
          Quando os visitantes deixam comentários no site, coletamos os dados
          mostrados no formulário de comentários, além do endereço de IP e de
          dados do navegador do visitante, para auxiliar na detecção de spam.
        </p>

        <p>
          Uma sequência anonimizada de caracteres criada a partir do seu e-mail
          (também chamada de hash) poderá ser enviada para o Gravatar para
          verificar se você usa o serviço. A política de privacidade do Gravatar
          está disponível aqui: https://automattic.com/privacy/. Depois da
          aprovação do seu comentário, a foto do seu perfil fica visível
          publicamente junto de seu comentário.
        </p>

        <h3>Mídia</h3>

        <p>
          Se você envia imagens para o site, evite enviar as que contenham dados
          de localização incorporados (EXIF GPS). Visitantes podem baixar estas
          imagens do site e extrair delas seus dados de localização.
        </p>

        <h3>Formulários de contato</h3>

        <h3>Cookies</h3>

        <p>
          Ao deixar um comentário no site, você poderá optar por salvar seu
          nome, e-mail e site nos cookies. Isso visa seu conforto, assim você
          não precisará preencher seus dados novamente quando fizer outro
          comentário. Estes cookies duram um ano.
        </p>

        <p>
          Se você tem uma conta e acessa este site, um cookie temporário será
          criado para determinar se seu navegador aceita cookies. Ele não contém
          nenhum dado pessoal e será descartado quando você fechar seu
          navegador.
        </p>

        <p>
          Quando você acessa sua conta no site, também criamos vários cookies
          para salvar os dados da sua conta e suas escolhas de exibição de tela.
          Cookies de login são mantidos por dois dias e cookies de opções de
          tela por um ano. Se você selecionar “Lembrar-me”, seu acesso será
          mantido por duas semanas. Se você se desconectar da sua conta, os
          cookies de login serão removidos.
        </p>

        <p>
          Se você editar ou publicar um artigo, um cookie adicional será salvo
          no seu navegador. Este cookie não inclui nenhum dado pessoal e
          simplesmente indica o ID do post referente ao artigo que você acabou
          de editar. Ele expira depois de 1 dia.
        </p>

        <h3>Mídia incorporada de outros sites</h3>

        <p>
          Artigos neste site podem incluir conteúdo incorporado como, por
          exemplo, vídeos, imagens, artigos, etc. Conteúdos incorporados de
          outros sites se comportam exatamente da mesma forma como se o
          visitante estivesse visitando o outro site.
        </p>

        <p>
          Estes sites podem coletar dados sobre você, usar cookies, incorporar
          rastreamento adicional de terceiros e monitorar sua interação com este
          conteúdo incorporado, incluindo sua interação com o conteúdo
          incorporado se você tem uma conta e está conectado com o site.
        </p>

        <h3>Análises</h3>

        <h2>Com quem partilhamos seus dados</h2>

        <h2>Por quanto tempo mantemos os seus dados</h2>

        <p>
          Se você deixar um comentário, o comentário e os seus metadados são
          conservados indefinidamente. Fazemos isso para que seja possível
          reconhecer e aprovar automaticamente qualquer comentário posterior ao
          invés de retê-lo para moderação.
        </p>

        <p>
          Para usuários que se registram no nosso site (se houver), também
          guardamos as informações pessoais que fornecem no seu perfil de
          usuário. Todos os usuários podem ver, editar ou excluir suas
          informações pessoais a qualquer momento (só não é possível alterar o
          seu username). Os administradores de sites também podem ver e editar
          estas informações.
        </p>

        <h2>Quais os seus direitos sobre seus dados</h2>

        <p>
          Se você tiver uma conta neste site ou se tiver deixado comentários,
          pode solicitar um arquivo exportado dos dados pessoais que mantemos
          sobre você, inclusive quaisquer dados que nos tenha fornecido. Também
          pode solicitar que removamos qualquer dado pessoal que mantemos sobre
          você. Isto não inclui nenhuns dados que somos obrigados a manter para
          propósitos administrativos, legais ou de segurança.
        </p>

        <h2>Para onde enviamos seus dados</h2>

        <p>
          Comentários de visitantes podem ser marcados por um serviço automático
          de detecção de spam.
        </p>

        <h2>Suas informações de contato</h2>

        <p>
          Para entrar em contato com o site, basta escrever para
          suporte@italomarsili.com.br
        </p>

        <h2>Informações adicionais</h2>

        <h3>Como protegemos seus dados</h3>

        <p>
          Seus dados pessoais enviados mediante cadastramento no site é
          armazenado em nossa base de dados para futuro relacionamento. Não
          compartilhamos de modo algum seus dados com terceiros.
        </p>
      </div>
    </div>
  </div>
</template>
